@use "../../styles/threejshelper" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;
@use "../../styles/typography" as *;


.privacy-policy {
    z-index: three-js-z-index(4); // Positioned on top of the instructions menu.
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    color: $clr-privacy-text;
    background-color: $clr-privacy-bg;

    &__header {
        padding-top: $padding-mob;
        padding-left: calc($padding-mob / 2);
        padding-right: calc($padding-mob / 2);
        display: flex;
        justify-content: flex-end;

        @include tablet {
            padding-top: $padding-tab;
            padding-left: $padding-tab;
            padding-right: $padding-tab;
        }

        @include tablet {
            padding-top: $padding-desktop;
            padding-left: $padding-desktop;
            padding-right: $padding-desktop;
        }
    }

    &__close-modal {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        & > img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        height: 100%;
        padding-left: $padding-mob;
        padding-right: $padding-mob;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1em;

        @include tablet {
            padding-left: $padding-tab;
            padding-right: $padding-tab;
        }

        @include tablet {
            padding-left: $padding-desktop;
            padding-right: $padding-desktop;
        }
    }

    &__title {
        padding-bottom: calc($padding-mob / 2);
        @include text-heading-1;

        @include tablet {
            padding-bottom: $padding-tab;
        }

        @include desktop {
            padding-bottom: $padding-desktop;
        }
    }

    &__text {
        padding-bottom: .75rem;
        
        @include tablet {
            padding-bottom: 1.5rem;
        }

        @include desktop {
            padding-bottom: 2rem;
        }
    }

    &__controller {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;

        @include tablet {
            justify-content: space-evenly;
        }

        @include desktop {
            justify-content: flex-end;
            gap: 2.5em;
        }
    }

    &__btn {
        font-size: .825rem;
        width: 50%;
        max-width: 175px;
        padding: .75em 1em;
        border-radius: 23px;
        color: $clr-privacy-btn-text;
        background-color: $clr-privacy-bg-alt;
    }
}