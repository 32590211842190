@use "./styles/resets" as *;
@use "./styles/variables" as *;

.app-content {
    position: relative;
    height: 100%;
    height: calc(100% - $header-height-mob);
    min-height: calc($screen-height-min - $header-height-mob);
    overflow: hidden;
    color: $clr-text-main;
}