@use "../../styles/variables" as *;
@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;
@use "../../styles/threejshelper" as *;
@use "../../styles/keyframes" as *;

.overlay {
    z-index: three-js-z-index(1);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(4px);
    animation: fade-in 750ms;

    @include tablet {
        display: flex;
        flex-direction: row-reverse;
    }

    &__content {
        width: 100%;

        &--text {
            position: relative;
            height: calc(100% - $video-container-height);
            padding: calc($padding-mob / 2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: .625rem;
            
            @include tablet {
                height: 100%;
                gap: 1rem;
            }

            @include desktop {
                gap: 2rem;
            }
        }

        &--video {
            height: $video-container-height;
            display: grid;
            place-items: center;

            @include tablet {
                height: 100%;
                border-right: $overlay-content-border;
            }
        }

        &--video-background {
            @include resp-background-img;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    &__title {
        @include text-heading-1;
        padding: calc($padding-mob / 2);
        border-bottom: $text-border-bottom;
    }

    &__quote {
        @include text-quote;
        display: block;
        font-weight: 700;
        padding-left: calc($padding-mob / 2);
        padding-right: calc($padding-mob / 2);
        
    }

    &__author {
        @include text-caption;
        padding-left: calc($padding-mob / 2);
        padding-right: calc($padding-mob / 2);
        
    }

    &__button {
        position: absolute;
        top: calc($padding-mob / 2);
        right: calc($padding-mob / 2);
        width: 45px;
        aspect-ratio: 1;

        @include tablet {
            position: static;
            width: 65px;
            margin-left: calc($padding-mob / 2);
        }

        & > img {
            width: 100%;
        }
    }

    &__video-wrapper {
        position: relative;
        width: 100%;
        max-width: 45vh;
        aspect-ratio: 1;
        display: grid;
        place-items: center;

        @include tablet {
            max-width: 65vh;
        }

        @include desktop {
            max-width: 75vh;
        }

        & > video {
            width: 100%;
            height: 100%;
            background-color: black;
            background-size: contain;
            aspect-ratio: 1 / 1;
        }
    }

    &__video-play-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px);  
        
        &--fade-out {
            animation-name: fade-out;
            animation-duration: 750ms;
            animation-fill-mode: forwards;
        }
    }
}

.fade-out--overlay {
    animation-name: fade-out-overlay;
    animation-duration: 750ms;
    animation-fill-mode: forwards;
}