@use "../../styles/typography" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.overlay-filter {
    &__content {
        height: 65%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
        @include tablet {
            height: 100%;
            border-right: $overlay-content-border;
        }

        &--filter-background {
            background-color: black;
        }
    }

    &__qr-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        & > img {
            width: 150px;
        }
    }

    &__footer {
        height: 65px;
        margin-bottom: 5vh;
    }

    &__text {
        @include text-footnote;
        text-align: center;
        max-width: 12.5rem;
    }

    &__redirect {
        background-color: #111;
        padding: .75em 1.25em;
        border-radius: 2.5em;

        &:focus, &:hover {
            background-color: #222;
        }
    }

    &__mob-hide {
        display: none;

        @include tablet {
            display: block;
        }
    }
    
}

.desktop-hide {
    @include desktop {
        display: none;
    }
}

.desktop-show {
    display: none;

    @include desktop {
        display: block;
    }
}