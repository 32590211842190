// Colours
$clr-main: #06C168;
$clr-alt: #142328; // Grey / Navy
$clr-highlight: #CE36C8;
$clr-highlight-active: #941890;
$clr-text-main: #fff;
$clr-text-alt: #B8BDBE;
$clr-border: #fff;
$clr-shadow: #00000040;
$clr-privacy-bg: #1f4919;
$clr-privacy-bg-alt: #19c265;
$clr-privacy-text: #cde4d3;
$clr-privacy-btn-text: #e5f7ec;

// Screen Sizes
$screen-width-min: 280px;
$screen-height-min: 600px;

$screen-width-tab: 768px;
$screen-width-desktop: 1280px;

// Component Sizes
$header-height-mob: 118px;
$header-height-tab-desk: 145px;

// Padding / Margin Sizes
$padding-mob: 1.75rem;
$padding-tab: 2.5rem;
$padding-desktop: 6.25rem;

// Shadow
$default-box-shadow: 0px 4px 4px 0px $clr-shadow;
$text-shadow-default: 0px 4px 4px $clr-shadow;

// Borders
$border-radius-default: 20px;
$text-border-bottom: 1px solid $clr-border;
$overlay-content-border: 3px solid $clr-border;

// Overlay Defaults
$video-container-height: 65%;