@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use '../../styles/threejshelper' as *;
@use '../../styles/keyframes' as *;

.splashscreen {
    z-index: three-js-z-index(4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $clr-alt;

    &--img {
        display: grid;
        place-items: center;
        
        & > img {
            width: 100%;

            @include tablet {
                max-width: 500px;
            }
        }
    }

    &__fade-out {
        animation: fade-out 1500ms forwards;
    }
}