@font-face {
    font-family: 'Uber Move 700';
    src: url('../assets/fonts/UberMoveText-Bold.woff2') format('woff2'),
        url('../assets/fonts/UberMoveText-Bold.woff') format('woff');
    // src: url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Bold.woff2') format('woff2'),
    // url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uber Move 400';
    src: url('../assets/fonts/UberMoveText-Regular.woff2') format('woff2'),
        url('../assets/fonts/UberMoveText-Regular.woff') format('woff');
    // src: url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Regular.woff2') format('woff2'),
    // url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uber Move 300';
    src: url('../assets/fonts/UberMoveText-Light.woff2') format('woff2'),
        url('../assets/fonts/UberMoveText-Light.woff') format('woff');
    // src: url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Light.woff2') format('woff2'),
    // url('http://cdn-hello-socail-uber-eats.s3-website-ap-southeast-2.amazonaws.com/fonts/UberMoveText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}