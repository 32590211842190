@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;
@use '../../styles/typography' as *;
@use '../../styles/threejshelper' as *;
@use '../../styles/keyframes' as *;

.instructions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    z-index: three-js-z-index(2);
    cursor: pointer;
    animation: fade-in 750ms;
    
    &__container {
        position: relative;
        display: grid;
        place-items: center;
        margin: 0 1rem;
        cursor: auto;
    }

    &__btn {
        position: absolute;
        top: .5rem;
        left: .5rem;

        & > img {
            width: 2.25rem;
        }
    }

    &__img {
        width: 100%;
        max-width: 400px;

        @include desktop {
            max-width: 500px;
        }
    }
}