@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/threejshelper" as *;

$poi-border-radius: 23px;

.poi-counter {
    z-index: three-js-z-index(0);
    position: absolute;
    right: 0;
    bottom: 13%;
    width: 245px;
    height: 98px;
    padding: 0.75rem;
    padding-right: 0;
    border-top-left-radius: $poi-border-radius;
    border-bottom-left-radius: $poi-border-radius;
    border: $overlay-content-border;
    border-right: none;
    background-color: $clr-main;
    background-image: url("../../assets/backgrounds/counter_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 350ms ease-in-out;
    cursor: pointer;

    @include desktop {
        bottom: 10%;
    }

    @include desktop {
        width: 367px;
        height: 141px;
    }

    &--visible {
        transform: translateX(0);
    }

    &--hidden {
        transform: translateX(85%);
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        & > button {
            width: 100%;
            height: 100%;
        }
    }

    &__img-container {
        height: 51px;
        display: grid;
        place-items: center;

        & > img {
            width: 40px;
            aspect-ratio: 1 / 1
        }
    }

    &__text {
        @include text-counter;
        color: black;
        height: 25px;

        &--hightlight {
            color: $clr-highlight;
        }   
    }
}