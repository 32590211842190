@use "./variables" as *;
@use "./fonts" as *;
@use "./mixins" as *;

@mixin text-heading-1 {
    font-family: 'Uber Move 700', 'Uber Move 400', Arial, Helvetica, sans-serif;
    font-size: 1.325rem;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: -0.3px;
    text-shadow: $text-shadow-default;

    @include tablet {
        font-size: 2rem;
    }

    @include desktop {
        font-size: 2.25rem;
    }
}

@mixin text-quote {
    font-family: 'Uber Move 400', Arial, Helvetica, sans-serif;
    text-shadow: $text-shadow-default;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0;

    @include tablet {
        font-size: 1.5rem;
        line-height: 1.5rem;

    }
    
    @include desktop {
        font-size: 1.75rem;
    }
}

@mixin text-caption {
    @include text-quote;
}

@mixin text-link {
    font-family: 'Uber Move 700', 'Uber Move 400', Arial, Helvetica, sans-serif;
    color: $clr-text-main;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.03rem;
    
    @include desktop {
        font-size: 2.5rem;
    }
}

@mixin text-footnote {
    font-family: 'Uber Move 300', 'Uber Move 400', Arial, Helvetica, sans-serif;
    color: $clr-text-main;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: -0.03rem;
}

@mixin text-counter {
    font-family: 'Uber Move 400', Arial, Helvetica, sans-serif;
    color: $clr-text-alt;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
    
    @include tablet {
        font-size: 1.313rem;
    }

    @include desktop {
        font-size: 1.5rem;
    }
}