@use "./variables" as *;

@mixin tablet {
    @media (min-width: $screen-width-tab) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $screen-width-desktop) {
        @content;
    }
}

@mixin window-height {
    height: calc(100% - $header-height-mob);

    @include tablet {
    height: calc(100% - $header-height-tab-desk);
    }
}

// Responsive Backgrounds
@mixin resp-background-img {
    background-image: url("../assets/backgrounds/sidebar-background-mobile.jpg");

    @include tablet {
        background-image: url("../assets/backgrounds/sidebar-background-tablet.jpg");
    }

    @include desktop() {
        background-image: url("../assets/backgrounds/sidebar-background-desktop.jpg");
    }
}