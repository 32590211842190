@use "../../styles/variables" as *;
@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;
@use "../../styles/threejshelper" as *;

.menu {
    z-index: three-js-z-index(2);
    position: absolute;
    top: $header-height-mob;
    bottom: 0;
    width: 100%;
    height: calc(100% - $header-height-mob);
    transition: left 150ms ease-in-out;

    @include desktop {
        backdrop-filter: blur(4px);
    }

    &--visible {
        left: 0;
    }

    &--hidden {
        left: -100%;
    }

    &__container {
        @include resp-background-img;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
            width: 60%;
        }
    }

    &__links-container {
        height: 90%;
        display: grid;
        place-items: center;
    }

    &__links {
        list-style: none;
        display: grid;
        gap: 69px;
        text-align: center;
    }

    &__link {
        @include text-link;
    }

    &__footer {
        height: 66px;
        padding-bottom: 2rem; 
    }

    &__footnote {
        @include text-footnote;
    }
}