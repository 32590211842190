@use "../../styles/threejshelper" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/keyframes" as *;

// Placeholder sizing

h2 {
    font-family: 'Uber Move 700', 'Uber Move 400', Arial, Helvetica, sans-serif;
    text-shadow: $text-shadow-default;
    font-size: 1.125rem;
    font-weight: bold;
    color: white;

    @include tablet {
        font-size: 1.5rem;
    }
}

p {
    font-family: 'Uber Move 400', Arial, Helvetica, sans-serif;
    text-shadow: $text-shadow-default;
    font-size: 1rem;
    color: white;

    @include tablet {
        font-size: 1.125rem;
    }
}
// 

.modal {
    z-index: three-js-z-index(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(4px);
    animation: fade-in 750ms;

    @include tablet {
        display: flex;
        flex-direction: row-reverse;
    }

}

.coupon {

    &__header {
        height: 25%;
        padding: calc($padding-mob / 2);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: white;

        @include tablet {
            width: 50%;
            height: 100%;
            justify-content: center;
            gap: 1rem;
        }
    }

    &__header-title-container {

        border-bottom: $text-border-bottom;        
        margin-bottom: calc($padding-mob / 4);
    }
    
    &__header-title {
        @include text-heading-1;
        padding: calc($padding-mob / 2);
        padding-bottom: calc($padding-mob / 4);
        
        @include tablet {
            width: 50%;
            max-width: 265px;
        }
    }

    &__header-text {
        @include text-quote;
        padding: 
            0 
            calc($padding-mob / 2) 
            calc($padding-mob / 4);

        &--bold {
            font-weight: 700;
        }
    }

    &__content {
        height: 75%;
        padding: calc($padding-mob / 2) $padding-mob;
        border-top: $overlay-content-border;
        ;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        @include tablet {
            width: 50%;
            height: 100%;
            justify-content: center;
            border-top: none;
            border-right: $overlay-content-border;
        }
    }

    &__content-segment {
        height: 100%;
        display: grid;
        place-items: center;
        
        @include tablet {
            height: 25%;
        }

        &--hide {
            @include tablet {
                display: none;
            }
        }
    }

    &__content-logo {
        height: 70%;
    }

    &__code {
        color: black;
        padding: .75rem 3rem;
        margin: 0.75rem auto;

        background-color: #D9D9D9;
    }

    &__content-btn {
        height: 70%;

        @include tablet {
            width: 65px;
            height: 65px;
            aspect-ratio: 1;
            margin-left: calc($padding-mob / 2);
        }

        &--hide-mob {
            display: none;

            @include tablet {
                display: block;
            }
        }

        & > img {
            height: 100%;
            max-width: 65px;
            max-height: 65px;
        }
    }
}