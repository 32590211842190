@use "../../styles/mixins" as *;

.map-canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    & canvas {
        // background-color: #8EC9DA;
    }
}