@use "../../styles/threejshelper" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;


.cookie-banner {
    z-index: three-js-z-index(3); // Positioned on top of the instructions menu.
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10%;
    padding-left: calc($padding-mob / 2);
    padding-right: calc($padding-mob / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .75em;
    color: $clr-privacy-text;
    background-color: $clr-privacy-bg;

    @include tablet {
        padding-left: $padding-tab;
        padding-right: $padding-tab;
        gap: 1.5em;
    }

    @include desktop {
        height: 7%;
        padding-left: $padding-desktop;
        padding-right: $padding-desktop;
    }

    &__text {
        width: 100%;
        font-size: 0.675rem;

        @include tablet {
            font-size: .875rem;
        }
    }

    &__link {
        color: $clr-privacy-bg-alt;
        text-decoration: underline;
    }

    &__btn {
        font-size: .825rem;
        width: 50%;
        max-width: 175px;
        padding: .75em 1em;
        border-radius: 23px;
        color: $clr-privacy-btn-text;
        background-color: $clr-privacy-bg-alt;
    }
}