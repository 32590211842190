.poi {
    cursor: pointer;
    user-select: none;
    transition: transform 150ms ease-in-out;
    // Reduce blur on POIs at distance
    backface-visibility: hidden;
    transform: translateZ(0);
    // 
    
    &:hover {
        transform: scale(1.2);
    }

    &__img {
        transition: opacity 750ms ease-in-out;

        &--complete {
            opacity: 0.5;
        }
    }
}