*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

html, body, #root {
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

body {
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

img {
    -webkit-user-drag: none;
}


a {
    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}