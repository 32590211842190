@use './threejshelper' as *;

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
        z-index: three-js-z-index(2);
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}

@keyframes fade-out-overlay {
    // Specific name currently used for filtering - is there a better approach?
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
        z-index: three-js-z-index(2);
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}