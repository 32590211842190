@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $header-height-mob;
    padding-left: $padding-mob;
    padding-right: $padding-mob;
    background-color: $clr-main;
    background-image: url("../../assets/backgrounds/header-background-mobile.jpg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    user-select: none;
    border-bottom: $overlay-content-border;

    @include tablet {
        padding-left: $padding-tab;
        padding-right: $padding-tab;
        background-size: cover;
    }
    
    @include desktop {
        background-image: url("../../assets/backgrounds/header-background-4k.jpg");
        padding-left: $padding-desktop;
        padding-right: $padding-desktop;
    }
    
    &__content {
        position: relative;
        width: 100%;
        height: 87px;
        display: flex;
        align-items: center;

        @include tablet {
            height: 100%;
        }
    }

    &__btn {
        z-index: 1;
        width: 2.5rem;
        transition: transform 150ms;

        @include tablet {
            width: 3.75rem;
        }

        &--close {
            transform: rotate(90deg);
        }

        &--open {
            transform: rotate(0deg);
        }

        & > img {
            width: 100%;
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__container-logo {
        position: absolute;
        left: 50%;
    }
    
    &__logo {
        transform: translateX(-50%);
        width: 100%;
        max-width: 200px;

        @include tablet {
            max-width: 400px;
        }
    }
}